.choose-customer-search {
  width: 100%;
  height: 40px;
}

.choose-customer-search-input-form-control {
  height: 40px;
  column-gap: 8px;
}

.MuiInputBase-input::-webkit-input-placeholder {
  opacity: 1 !important;
}
