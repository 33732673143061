.gscDetailsContainer {
  height: 100%;
  background: #222b36;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.gscDomain {
  color: white;
  margin-top: 16px;
  margin-left: 15px;
  margin-right: 15px;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
}

.signOutBtnGsc {
  padding: 12px;
  margin: 5px 0;
  text-align: center;
  font-size: 14px;
  background-color: #f44336;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 600;
}

@media only screen and (max-width: 480px) {
  .gscSignOutContainer {
    justify-content: center !important;
  }
}