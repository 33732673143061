.create-order-ghost-input {
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
  opacity: 0;
  margin-top: 1rem;
  z-index: 1;
  pointer-events: none;
}
