html,
body {
  height: 100%;
}

* {
  margin: 0px;
  border: 0px;
  padding: 0px;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#App-main {
  /* height: 93.4%; */
  /* padding-top: 4rem; */
  background: #242c36;
  display: flex;
  flex-flow: column;
  height: -webkit-fill-available;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Safari specific css  */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .MuiPaper-root {
      transition-duration: 0s !important;
    }
  }
}
